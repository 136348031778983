module.exports = [{
      plugin: require('/Users/thom/webdev/bejamas/bejamas-v2/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"130"},
    },{
      plugin: require('/Users/thom/webdev/bejamas/bejamas-v2/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":870},
    },{
      plugin: require('/Users/thom/webdev/bejamas/bejamas-v2/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://bejamas.io","stripQueryString":true},
    },{
      plugin: require('/Users/thom/webdev/bejamas/bejamas-v2/plugins/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-118672913-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/thom/webdev/bejamas/bejamas-v2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
