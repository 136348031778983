// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-template-blog-category-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/templates/template-blog-category.js" /* webpackChunkName: "component---src-templates-template-blog-category-js" */),
  "component---src-templates-template-blog-post-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/templates/template-blog-post.js" /* webpackChunkName: "component---src-templates-template-blog-post-js" */),
  "component---src-pages-404-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-estimate-project-index-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/estimate-project/index.js" /* webpackChunkName: "component---src-pages-estimate-project-index-js" */),
  "component---src-pages-estimate-project-thank-you-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/estimate-project/thank-you.js" /* webpackChunkName: "component---src-pages-estimate-project-thank-you-js" */),
  "component---src-pages-index-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-thank-you-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/newsletter/thank-you.js" /* webpackChunkName: "component---src-pages-newsletter-thank-you-js" */),
  "component---src-pages-privacy-policy-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-index-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-jamstack-website-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/services/jamstack-website.js" /* webpackChunkName: "component---src-pages-services-jamstack-website-js" */),
  "component---src-pages-services-progressive-web-app-js": () => import("/Users/thom/webdev/bejamas/bejamas-v2/src/pages/services/progressive-web-app.js" /* webpackChunkName: "component---src-pages-services-progressive-web-app-js" */)
}

